/*jshint multistr: true */

function Site(params) {
  'use strict';

  var options = params || {};
  var errors = 0;

  /**
   *  Attach any event handlers
   */
  $('.intro').on('click', '#arrow-pulser', function() {
    $("html, body").animate({ scrollTop: $('#web-artisans').offset().top - 40 }, 1000);
  })

  $('.intro').on('click', '#contact', function() {
    $("html, body").animate({ scrollTop: $('#contact-page').offset().top }, 1000);
    $('#name').focus();
  })
  /**
   *  Animate the text in the header
   */
  $('#slash span').sort(function(){
    return Math.random()*10 > 5 ? 1 : -1;
  }).each(function(index) {
    setTimeout(function() {
      $(this).addClass('flicker')
    }.bind(this), 100*(index+1))
  })

  setTimeout(function() {
    $('#thought span').sort(function(){
      return Math.random()*10 > 5 ? 1 : -1;
    }).each(function(index) {
      setTimeout(function() {
        $(this).addClass('flicker')
      }.bind(this), 100*(index+1))
    })
  }, 400);

  setTimeout(function() {
    $('#sub-header').addClass('active');
  }, 1200)

  // Add the active state to the pulser
  setTimeout(function() {
    $('#arrow-pulser').addClass('active');
  }, 1460)

  /**
   *  Contact form
   */
  $('#contact-form').submit(function(e) {
    e.preventDefault();
    errors = 0;

    if($('#contact-form button').hasClass('sending') ||
       $('#contact-form button').hasClass('sent')) {
      return false;
    }

    var name = $('#name').val(),
      number = $('#number').val(),
      email = $('#email').val(),
      description = $('#description').val();


    // Iterate through each textarea
    $('#contact-form input, #contact-form textarea').each(function(index) {
      if($.trim($(this).val()) === '') {
        errors++;
        $(this).closest('.input-wrapper').addClass('error');

        if($(this)[0].type === 'textarea') {
          $(this).addClass('error');
        }
      } else {
        $('input, textarea', '#contact-form').closest('.input-wrapper').removeClass('error');
      }
    })


    // If at least the phone OR email have a value, remove appropriate errors
    if($('#phone').val() !== '' && $('#email').val() === '') {
      $('#email').closest('.input-wrapper').removeClass('error');
      errors--;
    }

    if($('#email').val() !== '' && $('#phone').val() === '') {
      $('#phone').closest('.input-wrapper').removeClass('error');
      errors--;
    }

    // Focus on the first instance of any "error" classes
    $(':input', '#contact-form .error:first').focus();

    // No more errors? Great, send the email
    if(errors === 0) {

      $('button', $('#contact-form')).addClass('sending');

      var data = {
        name: name,
        number: number,
        email: email,
        description: description
      }

      var URL = 'https://xkuwqa6nkl.execute-api.us-east-1.amazonaws.com/prod/send';

      // Send the email
      $.ajax({
        type: 'POST',
        url: URL,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: function () {
          $('#contact-form button').removeClass('sending').addClass('sent');
          setTimeout(function() {
            $('#contact-form button').removeClass('sent');
          }, 2000);

          // Reset the form
          $('#contact-form')[0].reset();
        },
        error: function () {
          // show an error message

        }
      })
    }
   })

   /**
    * Input fields
    */
    $('input').on('focus', function() {
      $(this).closest('.input-wrapper').addClass('active')
    }).on('blur', function() {
      var $inputWrapper = $(this).closest('.input-wrapper');
      $inputWrapper.removeClass('active')
      if($.trim($(this).val()) !== '') {
        $inputWrapper.removeClass('error');
      } else {
        $(this).val('');
      }

      if($('#phone').val() !== '' && $('#email').val() === '') {
        $('#email').closest('.input-wrapper').removeClass('error');
        errors--;
      }

      if($('#email').val() !== '' && $('#phone').val() === '') {
        $('#phone').closest('.input-wrapper').removeClass('error');
        errors--;
      }
    });

    $('textarea', '#contact-form').on('blur', function() {
      var $this = $(this);
      if($.trim($(this).val()) !== '') {
        $this.removeClass('error');
      } else {
        $this.val('');
      }
    });
}
